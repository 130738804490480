/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "~@coreui/coreui/scss/coreui";


// Import Chart.js custom tooltips styles
@import "~@coreui/chartjs/scss/coreui-chartjs";

// perfect scrollbar theming for use with [perfectScrollbar] directive
//@import "~perfect-scrollbar/css/perfect-scrollbar.css";

@import "layout";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-angular-kanban/styles/material.css";
@import '../../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@import "~@ng-select/ng-select/themes/material.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_mixins';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/_utilities';
@import '~bootstrap/scss/_root';
@import '~bootstrap/scss/_reboot';
@import '~bootstrap/scss/_toasts';
@import '~bootstrap/scss/_buttons';

$toast-header-background-color: rgba($white, 0.5);
$toast-color: $white;
$toast-header-color: #333;

@import 'bootstrap/scss/bootstrap';

.form-control:focus {    
    background-color: transparent!important;
    border-color: transparent!important;
    outline: 0;
    box-shadow: none!important;
}

body {
    .mat-success {
        background-color: #155724;
        color: #f0fff3;
    }
    .mat-success:hover {
        color: #f0fff3;
        background-color: #1b742f;
    }

    .mat-secondary {
        background-color: #acb3bd;
        color: #f0fff3;
    }
    .mat-secondary:hover {
        background-color: #c5c7c5;
        color: #f0fff3;
    }
  
}

// *{
//     box-sizing: border-box;
//     outline: 1px solid green;
//     }
/* Border Color */
// .ng-select.ng-invalid.ng-touched .ng-select-container{
//     border-color: red;
// }
// /* Arrow Color */
// .ng-select.ng-invalid.ng-touched .ng-arrow-wrapper .ng-arrow{
//     border-color: red transparent transparent
// }
// /* Placeholder Color */
// .ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder{
//     color: red;
// }