.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  @include ltr-rtl("padding-right", var(--cui-sidebar-occupy-end, 0));
  will-change: auto;
  @include transition(padding .15s);
}

.sidebar{
  --cui-sidebar-bg: #01066B ;
}
